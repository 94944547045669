import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/components/LoginPage.vue'
import sessionTool from '@/utils/sessionTool'

let menu = JSON.parse(localStorage.getItem('menus_B'))
if (menu) {
  menu.sort((a, b) => a.id - b.id)
}

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

let redirectRoute = '';
if(menu && menu.length > 0 && menu[0]._child){
  redirectRoute = '/' + menu[0]._child[0].route.split('.').join('/');
  menu.forEach(element => {
    element._child.forEach(element_c=>{
      if (element_c.id == 12){
        redirectRoute = '/dialogue/apps';
      }
    })
  });  
}else{
  redirectRoute = '/login';
}

Vue.use(VueRouter)
const routes = [
  //默认输入地址跳转至首页向
  {
    path: '/',
    redirect: localStorage.getItem('menus_B') ? redirectRoute : '/login'
  },
  //登录页面
  {
    path: '/login',
    component: login
  },
  //前往页面编辑
  {
    path: '/webjs',
    name: 'webjs',
    component: () => import('@/views/theme')
  },
  //404页面
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404')
  },
  {
    path: '/',
    redirect: localStorage.getItem('menus_B') ? redirectRoute : '/login',
    component: () => import('@/components/globalMenu'),
    children: []
  }
  ,
  //downlaod
  {
    path: '/app',
    name: 'app',
    component: () => import('@/views/download')
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
let isNavigating = false;
export const bus = new Vue();
//前置守卫，没有token就会返回登录页
router.beforeEach((to, from, next) => {
  bus.$emit('showLoading');
  if (isNavigating) {
    // 上一个导航还未完成，直接进行当前导航
    console.log('上一个导航还未完成，直接进行当前导航');
    console.log(to);
    console.log(from);
    console.log(next);
    // this.$router.push({ name: to.name });
    next();
  } else {
    isNavigating = true;
    var currentPageUrl = window.location.href;
    var codeValue = currentPageUrl.split("?code=")[1];
    if (currentPageUrl.includes("?code=")) {
      var cleanUrl = currentPageUrl.split("?code=")[0];
      window.location.href = cleanUrl;
    }
    if (localStorage.getItem('Token')) {
      localStorage.removeItem('invite_code');
      next()

    } else {
      if (currentPageUrl.includes("?code=")) {
        var cleanUrl = currentPageUrl.split("?code=")[0];
        localStorage.setItem('invite_code', codeValue);  // 去掉code参数后的纯净地址
      }

      if (to.path == '/login' || to.path == '/app') {
        next()
      } else {
        // Message.error({
        //   message: '请登录后访问',
        //   duration: 2000
        // })  
        next('/login')
      }
    }
  }
})
router.afterEach(() => {
  isNavigating = false;
  bus.$emit('hideLoading');
});
router.onError(error => {
  const errorReg = /Loading chunk (.*) failed\./
  const failed = errorReg.test(error.message)
  if (failed) {
    location.reload()
  }
})

if (sessionTool.isLogin()) {
  sessionTool.getRoute(router)
}


export default router
